import { useCallback, useState } from 'react';
import { loadDefaultPerPage } from 'utils/paginate';

export type PaginatedListQueryParams = {
  page: number;
  perPage: number;
  perPageOptions: number[];
  searchKey: string;
  search?: string;
  sortBy?: string | undefined;
  sortDirection?: 'asc' | 'desc' | undefined;
};

export type PaginatedListState = ReturnType<typeof usePaginatedListState>;

export const usePaginatedListState = (initialParams: Partial<PaginatedListQueryParams> = {}) => {
  const [params, setParams] = useState<PaginatedListQueryParams>({
    page: 1,
    perPage: loadDefaultPerPage(initialParams.perPageOptions?.[0] || 10),
    perPageOptions: [10, 50, 100],
    searchKey: 'search',
    ...initialParams,
  });

  const setPage = useCallback((page: number) => setParams(prev => ({ ...prev, page })), []);

  const setPerPage = useCallback(
    (pageSize: number) => setParams(prev => ({ ...prev, perPage: pageSize, page: 1 })),
    []
  );

  const setSearch = useCallback(
    (search: string) => setParams(prev => ({ ...prev, search, page: 1 })),
    []
  );

  const setSort = useCallback(
    (
      sortBy: PaginatedListQueryParams['sortBy'],
      sortDirection: PaginatedListQueryParams['sortDirection']
    ) => setParams(prev => ({ ...prev, sortBy, sortDirection })),
    []
  );

  return {
    params,
    setPage,
    setPerPage,
    setSearch,
    setSort,
  };
};

export const serializeParamsForQuery = (
  params: PaginatedListQueryParams,
  additionalParams = {}
) => {
  const queryParams = {
    page: params.page,
    per_page: params.perPage,
    sort: params.sortBy,
    direction: params.sortDirection,
    [params.searchKey]: params.search || undefined,
    ...additionalParams,
  };

  const queryParamsString = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return queryParamsString;
};
